:root {
  --color-neutral-200: rgb(217, 218, 220);
  --color-neutral-white: rgb(255, 255, 255);
  --color-neutral-100: rgb(236, 237, 238);
  --theme-main-700: rgb(0, 53, 198);
  --color-neutral-400: rgb(142, 146, 152);
  --color-neutral-500: rgb(104, 110, 118);
  --color-neutral-700: rgb(53, 59, 67);
  --font-family: Roboto, sans-serif;
}

* {
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.idbs-page-header-icon-wrapper img {
  width: 25px;
  height: 25px;
}
/* Bold page title */
span.idbs-page-header-icon-wrapper + div h1{
  font-weight: 500!important;
  display: inline-block;
  margin-right: 5px;
}
span.idbs-page-header-icon-wrapper + div span{
  font-size: 22px;
  color: var(--theme-main-700);
  display: inline-block;
}
[data-testid="action-panel"] {
  padding-left: 10px!important;
}

[data-testid="idbs-react-modal-dialog-viewport"] {
  z-index: 3;
}
[data-testid="aws-region-input-option"] {
  background: var(--color-neutral-white)!important;
  color: var(--color-neutral-700)!important;
  font-size: 14px!important;
}
[data-testid="cancel-success-modal"] {
  margin-right: 7px;
}
[data-testid="aws-region-input-input"] {
  padding-left: 4px!important;
  font-size: 14px!important;
}
.idbs-react-text-input-container input, .page-body label {
  width: 750px;
  display: inline-block!important;
}

div[data-testid="aws-region-input-option"]:hover {
  background-color: var(--color-neutral-100)!important;
}
div[data-testid="aws-region-input-option"] {
  cursor: pointer!important;
}
div[data-testid="not-supported-placeholder"] {
  width: calc(100% / 12 * 8)!important;
  margin: 16px 0 0 16px;
}
